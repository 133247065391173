
import * as echarts from 'echarts';
import action from "@/actions.js"
export default {
    data(){
        return {
			totalorderamount:'',
			totalordercount:'',
			curstoreid:'',
			activename:'1',
			statistic:false,
			isloading:false,
			range:[],
			clientWidth: document.documentElement.clientWidth,
            status:'0',
            managerid:'',
            keyword:'',
			tabledata:[
				
			],
			labels:[
				{
					name:'日',
					value:'1'
				},
				{
					name:"周",
					value:'2'
				},
				{
					name:"月",
					value:"3"
				}
			]
        }
    },
	
    beforeCreate() {
		this.PAGELANG = this.LANG.pages.Storelist;
	},
	methods:{
		Goto(url){
			window.open(url,"_blank");
			//window.location.href = url;
		},
		formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2); 
                var second = ("0" + datetime.getSeconds()).slice(-2); 
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
		handleClick(){
			console.log(this.activename);
			this.axios.get(action.getstatis,{
				headers:{
					"content-type":"application"
				},
				params:{
					id:this.curstoreid,
					type:this.activename,
					range:JSON.stringify(this.range)
				}
			}).then((response)=>{
				console.log(response.data);
				this.totalorderamount = response.data.TotalOrderAmount;
				this.totalordercount = response.data.TotalOrderCount;
				let name = "statchart" + this.activename;
				this.MyCharts = echarts.init(document.getElementById(name));
				var option = {
					legend:{
						data:['GMV']
					},
					xAxis: {
						type:'category',
						data:response.data.xAxis
					},
					yAxis: {
						type: 'value'
					},
					datazoom:[
						{
							type:'inside',
							start: 0,
							end:20
						},
						{
							start: 0,
							end:20
						}
					],
					series: [
						{
							name:'GMV',
							data:response.data.yAxis,
							type:'bar'
							
						}
					]
				}
				this.MyCharts.setOption(option,true);
				
			});
		},
		Statistic(id){
			console.log(id);
			this.statistic = true;
			this.curstoreid = id;
			this.axios.get(action.getstatis,{
				headers:{
					"content-type":"application"
				},
				params:{
					id:id,
					type:this.activename,
					range:JSON.stringify(this.range)
				}
			}).then((response)=>{
				console.log(response.data);
				this.totalorderamount = response.data.TotalOrderAmount;
				this.totalordercount = response.data.TotalOrderCount;
				let name = "statchart" + this.activename;
				this.MyCharts = echarts.init(document.getElementById(name));
				var option = {
					legend:{
						data:['GMV']
					},
					
					xAxis: {
						type:'category',
						data:response.data.xAxis
					},
					yAxis: {
						type: 'value'
					},
					datazoom:[
						{
							type:'inside',
							start: 0,
							end:20
						},
						{
							start: 0,
							end:20
						}
					],
					series: [
						{
							name:'GMV',
							data:response.data.yAxis,
							type:'bar'
							
						}
					]
				}
				this.MyCharts.setOption(option,true);
				
			});
			
		},
		Authorize(id){
			this.axios.get(action.authorize,{
				headers:{
					"content-type":"application/json",
				},
				params:{
					storeid:id
				}
			}).then((response)=>{
				console.log(response.data);
			});
		},
		handleSizeChange(val){
			this.pagesize = val;
			this.init();
		},
		handleCurrentChange(val){
			this.curpage = val;
			this.init();
		},
		AddStore(){
			this.$router.push({path:'/customers/addstore'});
		},
		init(){
			this.isloading = true;
			this.axios.get(action.storelist,{
				headers:{
					"content-type":"application/json"
				},
				params:{
					curpage:this.curpage,
					pagesize:this.pagesize,
					keyword:this.keyword,
					status:this.status,
					managerid:this.managerid,
					range:JSON.stringify(this.range),
				}
			}).then((response)=>{
				console.log(response.data);
				this.tabledata = response.data.list;
				this.curpage = response.data.curpage*1;
				this.totalcount = response.data.totalcount*1;
				this.isloading = false;
			});
		},
		Delete(id){
			this.$confirm("确定要删除该店铺的信息么？","提示",{
				confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
			}).then(()=>{
				this.axios.get(action.delstore,{
					headers:{
						"content-type":"application/json"
					},
					params:{
						id:id
					}
				}).then((response)=>{
					console.log(response.data);
					if(response.data == "OK"){
						this.$message.success("操作成功");
						this.$router.go(0);
					}else{
						return false;
					}
				});
			});
		},
		Edit(id){
			this.$router.push({path:'/customers/addstore',query:{id:id,curpage:this.curpage}});
		}
	},
	created:function(){
		this.init();
		
	}
}
